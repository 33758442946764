
(function () {

  $(function() {

    ////////////////////////////////////////////////////////////////////////////
    // COMMON

    // FILE UPLOAD
    $("input[type=file]").on('change', function (e) {
      if (e.target.files[0]) {
        var filename = e.target.files[0].name || "";
        $("#fileupload-filename").text(filename);
        $(".fileupload-message").hide();
      }
    });

    ////////////////////////////////////////////////////////////////////////////
    // RESPONSIVE NAV TOGGLE

    const $navbar = $(".navbar");
    const $navbarNav = $(".navbar-nav");
    const $navbarToggle = $(".navbar-toggle");
    const $siteOverlay = $(".site-overlay");

    $navbarToggle.on("click", toggleNavbar);
    $siteOverlay.on("click", toggleNavbar);

    function toggleNavbar() {
      $siteOverlay.toggleClass("visible");
      $navbar.toggleClass("visible");
      $navbarToggle.toggleClass("close");
    }

    ////////////////////////////////////////////////////////////////////////////
    // PAGE SNIFFER

    const page = $('body').attr('data-page');


    ////////////////////////////////////////////////////////////////////////////
    // ITEMSET-BUILDER & ITEMSET-FILTERS PAGE
    const $autoScroll = $(".autoscroll");

    if (page === 'itemset-builder' || page === 'itemset-filters') {
	  $(window).keydown(function(event) {
		if (event.keyCode == 13) {
		  event.preventDefault();
		  return false;
		}
	  });

      ////////////////////////////////////////////////////////////////////////////
      // AUTO SCROLL

      function sizeAutoscroll() {

        const viewportWidth = $("body").width();
        console.log("WIDTH", viewportWidth + "px");


        if (viewportWidth >= 960) {

          const viewportHeight = document.documentElement.clientHeight;
          console.log("viewportHeight", viewportHeight);

          const $footer = $(".footer");
          const footerHeight = $footer.outerHeight();
          console.log("footerHeight", footerHeight);

          const $mainContent = $(".content-main");
          const mainContentTop = $mainContent.offset().top;
          console.log("mainContentTop", mainContentTop);

          const mainContentHeight = viewportHeight - (mainContentTop + footerHeight); // OMFG
          console.log("Main content height", mainContentHeight);

          $autoScroll.css({
            height: mainContentHeight + "px",
            overflowY: "auto",
            overflowX: "hidden"
          })

          // $autoScroll.perfectScrollbar("update");
          $autoScroll.perfectScrollbar({
            suppressScrollX: true // kill horizontal scroll
          });

        } else {
          $autoScroll.css({
            height: "auto",
            overflowY: "visible",
            overflowX: "visible"
          })

          $autoScroll.perfectScrollbar("destroy");
        }
      }

      sizeAutoscroll();
      $(window).on("resize", sizeAutoscroll);

    }


    ////////////////////////////////////////////////////////////////////////////
    // ITEMSET-BUILDER PAGE

    if (page === 'itemset-builder') {

      const $itemset = $(".itemset");
	  
	  const $noResults = $(".no-result");

      ////////////////////////////////////////////////////////////////////////////
      // BUILDER ACTION

      function changeBuilderAction() {
        if ($('form #itemset-save').length == 0) {
          var formAction = $('form').attr('action');
          var btnText = $('#export-btn').text();

          $('form').attr('action', formAction.replace('download', 'save'));
          $('#export-btn').text(btnText.replace('Download', 'Save'));

          $('form').prepend('<input type="hidden" id="itemset-save" name="itemset[save]" />');
        }
      }

      $itemset.on('keyup change', function() {
        changeBuilderAction();
      });

      ////////////////////////////////////////////////////////////////////////////
      // ITEMSET STORAGE

      $('#back-btn').click(function (e) {
        e.preventDefault();

        const redirect = $(this).attr('href');

        $.ajax({
          url : '/itemset_ajax.php',
          type : 'POST',
          data : {itemset : $('form').serialize()},
          success : function(data) {
            document.location.href = redirect;
          }
        });
      });

      ////////////////////////////////////////////////////////////////////////////
      // TOOLTIPS

      $('.tooltip').tooltipster({

      });

      // DRAG'N'DROP

      const drag = dragula([
        document.querySelector('.section--items .tiles'), // elements
      ],
      {
        isContainer: function (el) {
          return false; // only elements in drake.containers will be taken into account
        },
        moves: function (el, source, handle, sibling) {
          return true; // elements are always draggable by default
        },
        accepts: function (el, target, source, sibling) {
          // console.log(target);
          if ($(target).hasClass("tiles--items")) {
            return false;
          }
          return true; // elements can be dropped in any of the `containers` by default
        },
        invalid: function (el, handle) {
          return false; // don't prevent any drags from initiating by default
        },
        direction: 'horizontal',           // Y axis is considered when determining where an element would be dropped
        copy: function(el, source) {       // weather elements are moved (default) or copied
          return $(source).hasClass("tiles--items");
        },
        copySortSource: false,             // elements in copy-source containers can be reordered
        revertOnSpill: false,              // spilling will put the element back where it was dragged from, if this is true
        removeOnSpill: function(el, source) {
          return ! $(source).hasClass("tiles--items");
        },              // spilling will `.remove` the element, if this is true
        mirrorContainer: document.body,    // set the element that gets mirror elements appended
        ignoreInputTextSelection: true     // allows users to select input text, see details below
      });

      drag.on("drop", function(el, target, source, sibling) {
        // el was dropped into target before a sibling element, and originally came from source
        $el = $(el);
        $target = $(target);
        if ($target.attr("data-app-block-id") && $el.attr("data-app-id")) { // target is a valid itemset block
          const blockID = $target.attr("data-app-block-id");
          const itemID = $el.attr("data-app-id");
          $el.find("input[type='hidden']").remove();
          $(`<input type="hidden" name="itemset[blocks][${blockID}][items][]" value="${itemID}" />`).appendTo($el);
        }

        // Change builder action
        changeBuilderAction();
      });

      drag.on("remove", function(el, container, source) {
        changeBuilderAction();
      });

      // Dragula on load
      $('.itemset-block__content[data-app-block-id]').each(function() {
        const content = $(this)[0];
        drag.containers.push(content);
      });

      // DYNAMIC BLOCKS
      let itemsetBlockID = $('.itemset-block').length - (((nbItemsetBlocks = $('.itemset-block--add').length) > 0) ? nbItemsetBlocks : 0);

      $itemsetBlockAdd = $(".itemset-block--add");
      $itemsetBlockAdd.on("click", addItemsetBlock);
      $itemsetBlockDelete = $(".itemset-block--delete + label");
      $itemsetBlockDelete.on("click", deleteItemsetBlock);

      function deleteItemsetBlock() {
        $this = $(this);
        $this.closest(".itemset-block").remove();

        // Update scroller
        $autoScroll.perfectScrollbar('update');

        // Change builder action
        changeBuilderAction();
      }

      function addItemsetBlock() {

        $this = $(this);

        // Duplicate the add block
        $newAdd = $this.clone().appendTo($itemset);

        // Transform it into a regular block
        $this.removeClass("itemset-block--add");
        $this.find("p").remove();

        // Form submit tweaks
        $this.find(".itemset-block--data").each(function() {
          $(this).attr(
            "name",
            `itemset[blocks][${itemsetBlockID}][${$(this).attr('data-name')}]`
          );
        });

        // Event listener magic
        $(".itemset-block").off("click", addItemsetBlock);
        $newAdd.on("click", addItemsetBlock);
        $(".itemset-block--delete + label").on("click", deleteItemsetBlock);

        // Dragula
        const content = $this.find(".itemset-block__content")[0];
        drag.containers.push(content);
        $(content).attr("data-app-block-id", itemsetBlockID);

        // Increment ID
        itemsetBlockID++;

        // Update scroller
        $autoScroll.perfectScrollbar('update');

        // Change builder action
        changeBuilderAction();
      }


      // UI

      const $btnSubcategory = $(".app-filter-item-by-subcategory");
      const $btnSubcategoryAll = $(".app-filter-item-by-subcategory-all");
      const $searchItem = $(".app-search-item");

      const $item = $(".section--items .tile--item");

      $btnSubcategory.on("click", filterItemsBySubcategory);
      $btnSubcategoryAll.on("click", filterItemsByAll);
      $searchItem.on("keyup", searchItemName);

      // SEARCH ITEM

      function searchItemName() {

        const $this = $(this);
        const query = $this.val();

        if (query !== "") {
          $item.hide();

          $item.each(function() {
            const $this = $(this);
            const name = $this.attr("data-app-name");

            if (query && name) {
              const match = fuzzysearch(query, name);

              if (match) {
                $this.show();
              }
            }
          });

          // Uncheck "all items" button
          $btnSubcategoryAll.prop("checked", false);

        } else {
          filterItemsByAll();
        }
		
		if ($item.filter(":visible").length === 0) {
		  $noResults.show();
		} else {
          $noResults.hide();
		}

        // Reset item subcategory filters
        $btnSubcategory.prop("checked", false);

        // Update scroller
        $autoScroll.perfectScrollbar('update');
      }

      // FILTER ITEM

      function filterItemsByAll() {

        // Show all items
        $item.show();
		
		if ($item.filter(":visible").length === 0) {
		  $noResults.show();
		} else {
          $noResults.hide();
		}
		
        // Uncheck all category buttons
        $btnSubcategory.prop("checked", false);
		
        // Reset item search
        $searchItem.val("");
		
        // Check "all items" button
        $btnSubcategoryAll.prop("checked", true);

        // Update scroller
        $autoScroll.perfectScrollbar('update');
      }

      function filterItemsBySubcategory() {

        const $checkedBtnSubcategories = $btnSubcategory.filter(":checked");

        if ($checkedBtnSubcategories.length === 0) {
          filterItemsByAll();

        } else {
          const subcategories = [];

          $checkedBtnSubcategories.each(function() {
            const $this = $(this);
            const subcategory = $this.attr("data-app-subcategory");
            subcategories.push(".tile--item--" + subcategory);
          });

          const filterString = subcategories.join("");

          $item.hide();
          $item.filter(filterString).show();

          // Uncheck "all items" button
          $btnSubcategoryAll.prop("checked", false);
        }
		
		if ($item.filter(":visible").length === 0) {
		  $noResults.show();
		} else {
          $noResults.hide();
		}

        // Reset item search
        $searchItem.val("");

        // Update scroller
        $autoScroll.perfectScrollbar('update');
      }

    }


    ////////////////////////////////////////////////////////////////////////////
    // ITEMSET-FILTERS PAGE

    if (page === 'itemset-filters' || page === 'itemsets-champion') {

      const $btnRole = $(".app-filter-champion-by-role");
      const $searchChampion = $(".app-search-champion");

      const $champion = $(".content-main .tile--champion");
      const $championAny = $(".content-main .tile--champion.tile--any");
      const $championAnyImg = $(".content-main .tile--champion.tile--any img");
      const championAnyImg = '/dist/img/tile-any-champion.png';
      const championAnyImgUnselected = '/dist/img/tile-any-champion-unselected.png';
      const $map = $(".content-main .tile--map");
      const $mapAny = $(".content-main .tile--map.tile--any");
      const $mapAnyImg = $(".content-main .tile--map.tile--any img");
      const mapAnyImg = '/dist/img/tile-any-map.png';
      const mapAnyImgUnselected = '/dist/img/tile-any-map-unselected.png';

      const $selectedChampions = $("#selected-champions");
      const $selectedMaps = $("#selected-maps");
	  
	  const $noResults = $(".no-result");

      $champion.on("click", selectChampion);
      $map.on("click", selectMap);
      $btnRole.on("click", filterChampionsByRole);
      $searchChampion.on("keyup", searchChampionName);


      // SEARCH CHAMPION

      function searchChampionName() {

        const $this = $(this);
        const query = $this.val();

        if (query !== "") {
          $champion.hide();

          $champion.each(function() {
            const $this = $(this);
            const name = $this.attr("data-app-name");

            if (query && name) {
              const match = fuzzysearch(query, name);

              if (match) {
                $this.show();
              }
            }
          });
        } else {
          $champion.show();
        }
		
		if ($champion.filter(":visible").length === 0) {
		  $noResults.show();
		} else {
          $noResults.hide();
		}

        // Reset champion filters
        $btnRole.prop("checked", false);
        $btnRole.filter(".app-filter-champion-by-role--all").prop("checked", true);

        // Update scroller
        $autoScroll.perfectScrollbar('update');
      }

      // FILTER CHAMPION

      function filterChampionsByRole() {

        const $this = $(this);
        const role = $this.attr("data-app-role");

        if (role === "ALL") {
          $champion.show();
          $championAny.show();
        } else {
          $champion.hide();
          $championAny.hide();
          $champion.filter(".tile--champion--" + role).show();
        }
		
		if ($champion.filter(":visible").length === 0) {
		  $noResults.show();
		} else {
          $noResults.hide();
		}

        // Reset champion search
        $searchChampion.val("");

        // Update scroller
        $autoScroll.perfectScrollbar('update');
      }

      // SELECTED TILES

      function selectChampion() {

        const $this = $(this);

        if ($this.hasClass("tile--any")) {
          $champion.removeClass("tile--selected");
          $champion.find(".tile__hidden-input").prop("checked", false);
          $this.addClass("tile--selected");
          $championAnyImg.attr('src', championAnyImg);

        } else { // Other champions
          $championAny.removeClass("tile--selected");
          $championAnyImg.attr('src', championAnyImgUnselected);
          $this.toggleClass("tile--selected");

          const $hiddenInput = $this.find(".tile__hidden-input");
          if ($hiddenInput.prop("checked")) {
            $hiddenInput.prop("checked", false);
          } else {
            $hiddenInput.prop("checked", true);
          }
        }

        // Selected champion(s) update

        // Number of checked champions (zero for ANY)
        const $checkedChampions = $champion.filter(":has(.tile__hidden-input:checked)");

        $selectedChampions.empty();

        if ($checkedChampions.length === 0) { // Any champion
          $championAnyImg.attr('src', championAnyImg);
          $championAny.addClass("tile--selected").clone().appendTo($selectedChampions);

        } else { // Multiple champions
          $checkedChampions.each( function(index) {
            $addSelectedChampion = $(this).clone().appendTo($selectedChampions);
            $addSelectedChampion.show();
          } );
        }

        // Remove selected tile style and add passive style
        $selectedChampions.find(".tile--selected")
        .removeClass("tile--selected")
        .addClass("tile--passive");
      }

      function selectMap() {

        const $this = $(this);

        if ($this.hasClass("tile--any")) {
          $map.removeClass("tile--selected");
          $map.find(".tile__hidden-input").prop("checked", false);
          $this.addClass("tile--selected");
          $mapAnyImg.attr('src', mapAnyImg);

        } else { // Other maps
          const $hiddenInput = $this.find(".tile__hidden-input");

          if ($hiddenInput.prop("checked")) {
            $hiddenInput.prop("checked", false);
            $this.removeClass("tile--selected");

            $mapAny.addClass("tile--selected");
            $mapAnyImg.attr('src', mapAnyImg);
          } else {
            $map.find(".tile__hidden-input").prop("checked", false);
            $hiddenInput.prop("checked", true);

            $map.removeClass("tile--selected");
            $mapAny.removeClass("tile--selected");
            $this.addClass("tile--selected");
            $mapAnyImg.attr('src', mapAnyImgUnselected);
          }
        }

        // Selected map(s) update

        // Number of checked maps (zero for ANY)
        const $checkedMaps = $map.filter(":has(.tile__hidden-input:checked)");

        $selectedMaps.empty();

        if ($checkedMaps.length === 0) { // Any map
          $mapAny.addClass("tile--selected").clone().appendTo($selectedMaps);

        } else { // Multiple maps
          $checkedMaps.each( function(index) {
            $(this).clone().appendTo($selectedMaps);
          } );
        }

        // Remove selected tile style and add passive style
        $selectedMaps.find(".tile--selected")
        .removeClass("tile--selected")
        .addClass("tile--passive");
      }

    }


  });


  // VENDOR / NPM DUMPS

  // Nicolas Bevecqua's fuzzy search
  // See : https://github.com/bevacqua/fuzzysearch

  function fuzzysearch (needle, haystack) {
    var tlen = haystack.length;
    var qlen = needle.length;
    if (qlen > tlen) {
      return false;
    }
    if (qlen === tlen) {
      return needle === haystack;
    }
    outer: for (var i = 0, j = 0; i < qlen; i++) {
      var nch = needle.charCodeAt(i);
      while (j < tlen) {
        if (haystack.charCodeAt(j++) === nch) {
          continue outer;
        }
      }
      return false;
    }
    return true;
  }

})();
